import tw from "twin.macro"
import * as React from "react"
import { ButtonA } from "../Buttons/Button"
import AnalyticsEventsClient from "../../clients/AnalyticsEvents";
import { eventNames } from "process";

const Button: React.FC<{ CTA: string; link: string, eventName: string}> = ({ CTA, link, eventName }) => {

  return (
    <ButtonA href={link} target={"_blank"} primary eventMessage={`Visitor clicked ${link}`} eventName={eventName}>
      {CTA}
    </ButtonA>
  )
}

export default Button
