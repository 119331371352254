import { Children } from "react"
import tw from "twin.macro"

const OnThisPage: React.FC<{children: any}> = ({children}) => {
  return (
    <div css={[tw`bg-red-300 text-white w-full p-4 lg:p-10`]}>
      <h4 css={[tw`text-center text-3xl`]}>on this page</h4>
      {children}
    </div>
  )
}

export default OnThisPage
