import { eventNames } from "process"
import tw from "twin.macro"
import AnalyticsEventsClient from "../../clients/AnalyticsEvents"
import { UpArrow } from "../Icons/UpArrow"


const PageSection: React.FC<{onClick: any, sectionTitle: string, id: string, eventName: string}> = ({sectionTitle, onClick, id, eventName}) => {
    
  // handle analytic events for on page links
    const handleAnalyticsEvent = (link: string, eventName: string) => {
      AnalyticsEventsClient.event({
        eventType: "click",
        eventName: eventName,
        eventMessage: `Visitor clicked ${link}`,
        visitorID: localStorage.getItem("visitorID")
      })
    }
  // handle all onclick functions
      const handleClick = () => {
        handleAnalyticsEvent(sectionTitle, eventName);
        onClick()
      }
  
    return (
        <a href={id} onClick={handleClick}>
      <div css={[tw`flex my-3 items-center content-center mt-4`]}>
        <UpArrow css={[tw`rotate-90 mr-3 h-5`]}/> <p css={[tw`hover:underline`]}>{sectionTitle}</p>
      </div>
    </a>
    )
}

export default PageSection