import { ReactChild } from "react"
import tw from "twin.macro"
import AnalyticsEventsClient from "../../clients/AnalyticsEvents"
import { UpArrow } from "../Icons/UpArrow"



const Section: React.FC<{header: string, open:any, onClick: any, id:string, eventName: string}> = ({header, open, onClick, id, children, eventName, ...remainingProps}) =>{ 
   
  // handle analytic events for on page links
    const handleAnalyticsEvent = (link: string, eventName: string) => {
      AnalyticsEventsClient.event({
        eventType: "click",
        eventName: eventName,
        eventMessage: `Visitor clicked ${link}`,
        visitorID: localStorage.getItem("visitorID")
      })
    }
  // handle all onclick functions
      const handleClick = () => {
        handleAnalyticsEvent(header, eventName);
        onClick()
      }

    return(
       
  <div id={id} css={[tw`col-span-1 lg:col-span-7 text-gray-700 `]}>
          <div css={[tw`flex my-3 items-center content-center justify-between`]} onClick={handleClick}>
              <h4>{header}</h4> <UpArrow css={open ? [tw`transition-transform duration-500`] : [tw`rotate-180 transition-transform duration-500`]} /> 
          </div>
         <div css={open ? [tw`bg-gray-200 p-5 lg:p-16 max-h-full ease-in-out transition duration-500`] : [tw`max-h-0 ease-in-out transition-transform duration-500`]}>
            {( open && <div >
              {children}
            </div>)}
          </div>
        </div>
    )
}

export default Section